<template>
  <div>
    <blog-index v-if="isAuthenticated"></blog-index>
  </div>
</template>

<script>
import { useDevice } from '@/composables/device'
import { useUserStore } from '@/stores/user'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import BlogIndex from '@/pages/BlogIndex.vue'

export default {
  components: {
    BlogIndex,
  },
  setup() {
    const device = useDevice()
    const userStore = useUserStore()
    const { isAuthenticated } = storeToRefs(userStore)
    const router = useRouter()

    onMounted(async () => {
      await userStore.fetchUserData()

      if (!isAuthenticated.value && !device.isMobile()) {
        router.push({
          name: 'FuturePriceGraph',
          params: {
            util: 'e',
          },
        })
      }
    })

    return {
      isAuthenticated,
    }
  },
}
</script>
