<template>
  <div>
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-default px-1 d-none d-md-inline-block pt-0 float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.graph.backtooverview') }}
      <span
        v-if="userStore.isAuthenticated && blogStore.unvisitedPostCount"
        class="badge ml-2"
        >{{ blogStore.unvisitedPostCount }}</span
      >
    </router-link>

    <div class="pricingTitle">
      <h3>
        <utility-icon :util="searchParameters.utilitytype.key"></utility-icon>
        <span>{{ graphTitle }}</span>
        <i
          class="fas fa-info-circle black"
          v-tooltip:top.html="tooltip"
          v-if="showToolTip"
        ></i>
      </h3>
    </div>

    <settings-modal ref="settingsModal" :title="$t('general.filters')">
      <div class="row">
        <div class="d-none d-md-block col-md-4">
          <label for="utility">{{ $t('pages.graph.utility') }}</label>
          <default-multiselect
            id="utility"
            label="name"
            trackBy="value"
            :options="utilityTypeStore.translatedUtilityTypes"
            v-model="searchParameters.utilitytype"
            @update:model-value="onUtilityChanged"
          >
          </default-multiselect>
        </div>

        <div class="col-md-4">
          <label for="product">{{ $t('pages.graph.product') }}</label>
          <default-multiselect
            id="product"
            label="stripname"
            trackBy="stripname"
            :multiple="true"
            :limit="2"
            :options="productsForMarket"
            :searchable="true"
            :group-select="true"
            group-label="name"
            group-values="products"
            v-model="searchParameters.stripname"
            @update:model-value="productsChanged"
          >
          </default-multiselect>
        </div>

        <div class="col-md-4" v-if="hasPriceCategories">
          <label for="priceCategory">{{
            $t('pages.graph.price_category')
          }}</label>
          <default-multiselect
            id="priceCategory"
            label="name"
            :options="priceCategoryStore.translatedPriceCategories"
            v-model="priceCategory"
            @update:model-value="priceFieldChanged"
          >
          </default-multiselect>
        </div>

        <div class="col-md-4">
          <label for="startDate">
            {{ $t('pages.graph.start') }}
          </label>
          <default-datetime
            v-model="startDate"
            @update:model-value="onStartDateChanged"
            id="startDate"
            :max="endDate"
          />
        </div>
        <div class="col-md-4">
          <label for="endDate">
            {{ $t('pages.graph.stop') }}
          </label>
          <default-datetime
            v-model="endDate"
            @update:model-value="onEndDateChanged"
            id="endDate"
            :max="now"
          />
        </div>

        <div class="col-md-4">
          <template v-if="hasAreas">
            <label for="area">{{ $t('pages.graph.area') }}</label>
            <default-multiselect
              id="area"
              label="name"
              trackBy="value"
              :options="availableAreas"
              v-model="searchParameters.area"
              @update:model-value="areaChanged"
            >
            </default-multiselect>
          </template>
        </div>

        <div class="col-md-12" :class="fastSearchClass">
          <button class="btn btn-light" @click="setPeriodByYearAgo(0)">
            {{ this.getPeriodTranslation(0) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(1)">
            {{ this.getPeriodTranslation(1) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(2)">
            {{ this.getPeriodTranslation(2) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(3)">
            {{ this.getPeriodTranslation(3) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(5)">
            {{ this.getPeriodTranslation(5) }}
          </button>
        </div>
      </div>
    </settings-modal>

    <settings-modal ref="exportModal" :title="$t('pages.graph.download')">
      <div class="row d-md-none">
        <div class="d-md-none col-md-4">
          <label>{{ $t('general.export-type') }}</label>
          <default-multiselect
            label="name"
            trackBy="value"
            :options="exportTypeStore.exportTypes"
            v-model="exportType"
          >
          </default-multiselect>
        </div>

        <div class="d-md-none col-md-4">
          <button class="btn btn-danger" type="button" @click="doExport">
            {{ $t('pages.graph.download') }}
          </button>
        </div>
      </div>
    </settings-modal>

    <div class="graph-buttons">
      <button @click="openSettings" class="btn btn-ghost">
        <i class="fas fa-sliders-h" aria-hidden="true"></i>
      </button>
      <button
        v-if="(!device.isIOS() || !device.isPWA()) && userStore.isAuthenticated"
        @click="openExportSettings"
        class="btn btn-ghost"
      >
        <i class="fas fa-download" aria-hidden="true"></i>
      </button>
    </div>

    <div
      v-if="!device.isIOS() || (!device.isPWA() && userStore.isAuthenticated)"
      class="dropdown"
    >
      <button
        class="btn btn-ghost d-none d-lg-inline-block float-right dropdown-toggle mb-3"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ $t('pages.graph.download') }}
      </button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item"
          v-for="exportType in exportTypeStore.exportTypes"
          :key="exportType.name"
          @click="exportTypeChosen(exportType)"
          style="cursor: pointer"
        >
          {{ formatter.name(exportType.name) }}
        </a>
      </div>
    </div>
    <div class="btn btn-ghost d-none d-md-inline-block float-right mb-3" v-else>
      &nbsp;
    </div>

    <!-- :key attribute is crucial for the export functionality. We basically force a re-render of the component on any change
             of the searchParameters.utilitytype.value variable located in the data section. If not used the this.$refs.chart
             object will not change as the component has not re-rendered after changing the utility type.-->
    <highcharts
      class="graph-container"
      :options="chartOptions"
      ref="graph"
      :key="shouldRerender"
    ></highcharts>

    <span v-if="error" class="graph-error">
      <i class="fas fa-exclamation-triangle mr-2"></i
      >{{ $t('general.data_error') }}
    </span>
  </div>
</template>

<script>
import lodash from 'lodash'
import { DateTime } from 'luxon'
import { trans } from 'laravel-vue-i18n'
import { formatter } from '@/formatter'

import chartDefaults from '@/chartDefaults'
import chartDefaultsMobile from '@/chartDefaultsMobile'

import { END_DATE_NCG_GPL_MARKETS, CONVERSION_RATE } from '@/contstants'

import { useAppInsights } from '@/composables/appInsights'
import { useDevice } from '@/composables/device'

import { useCountryStore } from '@/stores/country'
import { useAreaStore } from '@/stores/area'
import { usePriceCategoryStore } from '@/stores/priceCategory'
import { usePriceTypeStore } from '@/stores/priceType'
import { useUtilityTypeStore } from '@/stores/utilityType'
import { useProductStore } from '@/stores/product'
import { useExportTypeStore } from '@/stores/exportType'
import { useFuturePriceStore } from '@/stores/futurePrice'
import { useMarketStore } from '@/stores/market'
import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { useUserStore } from '@/stores/user'
import { useBlogStore } from '@/stores/blog'

import DefaultMultiselect from '@/components/common/DefaultMultiselect.vue'
import DefaultDatetime from '@/components/common/DefaultDatetime.vue'
import SettingsModal from '@/components/general/SettingsModal.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'
import { inject, onMounted } from 'vue'

export default {
  components: {
    DefaultMultiselect,
    DefaultDatetime,
    SettingsModal,
    UtilityIcon,
  },
  data() {
    const startDate = DateTime.now().startOf('day').minus({ months: 4 })
    const endDate = DateTime.now().startOf('day')

    return {
      startDate,
      endDate,
      error: false,
      now: DateTime.now(),
      shouldRerender: false,
      seriesColors: {
        e: {},
        g: {},
      },
      priceCategory: {},
      availableAreas: [],
      searchParameters: {
        area: {},
        utilitytype: {},
        stripname: [],
        market: null,
        country: null,
        order: ['date:asc'],
        pricetype: null,
        select: ['stripname', 'date', 'base', 'peak', 'offpeak'],
      },
      exportType: {
        name: 'pdf',
        value: 'application/pdf',
      },
    }
  },
  setup() {
    const appInsights = useAppInsights()
    const device = useDevice()

    const areaStore = useAreaStore()
    const countryStore = useCountryStore()
    const exportTypeStore = useExportTypeStore()
    const futurePriceStore = useFuturePriceStore()
    const marketStore = useMarketStore(false)
    const mobileUtilityStore = useMobileUtilityStore()
    const priceCategoryStore = usePriceCategoryStore()
    const priceTypeStore = usePriceTypeStore()
    const productStore = useProductStore(false)
    const utilityTypeStore = useUtilityTypeStore()
    const userStore = useUserStore()
    const pdfExportHost = inject('pdf-export-host')
    const blogStore = useBlogStore()

    return {
      appInsights,
      device,
      countryStore,
      priceCategoryStore,
      priceTypeStore,
      areaStore,
      utilityTypeStore,
      productStore,
      exportTypeStore,
      futurePriceStore,
      marketStore,
      mobileUtilityStore,
      userStore,
      formatter,
      pdfExportHost,
      blogStore,
    }
  },
  computed: {
    graphTitle() {
      if (!this.searchParameters.country) {
        return ''
      }

      return [
        this.$t('general.market.future'),
        this.countryStore.country.code.toUpperCase(),
        this.hasAreas && this.searchParameters.area
          ? this.searchParameters.area.name
          : '',
        this.searchParameters.utilitytype.name,
        this.priceCategory.name,
      ].join(' ')
    },
    tooltip() {
      if (this.searchParameters.utilitytype.key === 'g') {
        return this.$t(
          `general.marketNames.g.${this.countryStore.country.code}.future`,
        )
      }

      return this.$t(
        `general.marketNames.e.${this.countryStore.country.code}.future.${this.priceCategory.value}`,
      )
    },
    showToolTip() {
      if (
        (this.countryStore.country.code === 'nl' &&
          this.priceCategory.value === 'base') ||
        (this.countryStore.country.code === 'nl' &&
          this.priceCategory.value === 'peak')
      ) {
        return true
      } else if (
        (this.countryStore.country.code === 'de' &&
          this.priceCategory.value === 'base') ||
        (this.countryStore.country.code === 'de' &&
          this.priceCategory.value === 'peak')
      ) {
        return true
      } else if (
        this.countryStore.country.code === 'be' &&
        this.priceCategory.value === 'base'
      ) {
        return true
      }
      return false
    },
    productsForMarket() {
      if (!this.productStore.products.length || !this.markets.length) return []
      const country = this.searchParameters.country
        ? this.searchParameters.country
        : this.countryStore.country.code

      const minCal = parseInt(
        DateTime.now().startOf('year').toFormat('yyyyLLdd'),
      )
      const maxCal = parseInt(
        DateTime.now().plus({ years: 5 }).toFormat('yyyyLLdd'),
      )
      const minQ = parseInt(
        DateTime.now().minus({ quarters: 1 }).toFormat('yyyyLLdd'),
      )
      const maxQ = parseInt(
        DateTime.now().plus({ quarters: 3 }).toFormat('yyyyLLdd'),
      )

      return this.productStore.products
        .filter((product) => {
          return (
            product.markettype === 'future' &&
            product.country.toLowerCase() === country &&
            (product.period === 'Q' || product.period === 'Y')
          )
        })
        .reduce(
          (groups, product) => {
            if (
              product.period === 'Y' &&
              product.datekey > minCal &&
              product.datekey < maxCal
            ) {
              groups[0].products.push(product)
            } else if (
              product.period === 'Q' &&
              product.datekey > minQ &&
              product.datekey < maxQ
            ) {
              groups[1].products.push(product)
            } else if (product.period === 'Y') groups[2].products.push(product)
            else if (product.period === 'Q') groups[3].products.push(product)
            else groups[4].products.push(product)
            return groups
          },
          [
            {
              name: this.$t('component.graphs.recent Cal'),
              products: [],
            },
            {
              name: this.$t('component.graphs.recent Q'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other-cal'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other-q'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other'),
              products: [],
            },
          ],
        )
    },
    firstProduct() {
      const countryCode = this.searchParameters.country
        ? this.searchParameters.country
        : this.countryStore.country.code

      const routeProduct = this.productStore.products.find((product) => {
        return (
          product.markettype === 'future' &&
          (product.period === 'Q' || product.period === 'Y') &&
          product.country.toLowerCase() === countryCode &&
          product.stripname === this.$route.params.product
        )
      })

      if (routeProduct) return routeProduct

      const minCal = parseInt(
        DateTime.now().startOf('year').toFormat('yyyyLLdd'),
      )
      const maxCal = parseInt(
        DateTime.now().plus({ years: 1 }).toFormat('yyyyLLdd'),
      )

      return this.productStore.products.find((product) => {
        return (
          product.markettype === 'future' &&
          product.period === 'Y' &&
          product.country.toLowerCase() === countryCode &&
          product.datekey > minCal &&
          product.datekey < maxCal
        )
      })
    },
    hasAreas() {
      return (
        (this.searchParameters.country === 'de' ||
          this.searchParameters.country === 'at') &&
        this.searchParameters.utilitytype.key === 'g'
      )
    },
    hasPriceCategories() {
      return this.searchParameters.utilitytype.key !== 'g'
    },
    convertsPrice() {
      return (
        this.searchParameters.country === 'nl' &&
        this.searchParameters.utilitytype.key === 'g'
      )
    },
    optionsMobile() {
      if (this.device.isMobile()) {
        return chartDefaultsMobile
      }
      return chartDefaults
    },
    chartOptions() {
      const __chart = this
      return {
        ...chartDefaults,
        colors:
          chartDefaults.utilityColors[this.searchParameters.utilitytype.key],
        chart: {
          ...chartDefaults.chart,
          height: this.device.isMobile() ? null : chartDefaults.chart.height,
        },
        title: {
          text: this.graphTitle,
        },
        series: this.series,
        tooltip: {
          formatter() {
            const dateLabel = __chart.hasWeeklyData
              ? trans('general.week') +
                ' ' +
                DateTime.fromMillis(this.x).toFormat('W yyyy')
              : DateTime.fromMillis(this.x).toFormat('d LLL yyyy')

            const priceLabel =
              trans('component.chart.price') +
              ': ' +
              __chart.yAxisLabel +
              ' ' +
              this.y.toFixed(2).replace('.', ',')

            return (
              `<b>${this.series.name}</b>` +
              '<br>' +
              dateLabel +
              '<br>' +
              priceLabel
            )
          },
        },
        xAxis: {
          ...this.optionsMobile.xAxis,
        },
        yAxis: {
          title: {
            text: this.yAxisLabel,
            offset: 15,
            x: this.device.isMobile() ? -30 : -37,
          },
          labels: {
            format: '{value}',
            style: {
              fontSize: '14px',
            },
            x: -5,
          },
        },
        legend: {
          ...this.optionsMobile.legend,
        },
      }
    },
    yAxisLabel() {
      if (this.convertsPrice) return '€ct/m3'
      return '€/MWh'
    },
    series() {
      const priceProperty =
        !this.hasPriceCategories || !this.priceCategory
          ? 'base'
          : this.priceCategory.value

      const series = this.futurePriceStore.graphPrices.reduce(
        (series, entry) => {
          const name = entry.stripname
          let price = parseFloat(entry[priceProperty])

          if (!series[name]) {
            series[name] = {
              name,
              data: [],
              color: this.getConsistentColor(name),
            }
          }

          if (this.convertsPrice) {
            price *= CONVERSION_RATE
          }

          series[name].data.push({
            y: price,
            x: DateTime.fromFormat(entry.date, 'yyyyLLdd').valueOf(),
            printInfo: {
              type: this.exportType,
              contact: this.userStore.contact,
              country: this.searchParameters.country,
              host: this.pdfExportHost,
            },
          })

          return series
        },
        [],
      )

      return Object.values(series)
    },
    searchParametersAreSet() {
      return (
        this.startDate &&
        this.endDate &&
        this.searchParameters.stripname.length &&
        (this.searchParameters.market || this.markets.length) &&
        (!this.hasAreas || this.searchParameters.area)
      )
    },
    markets() {
      const countryCode =
        this.searchParameters.country ?? this.countryStore.country.code

      return this.marketStore.futureMarkets.filter((market) => {
        return (
          market.country.toLowerCase() === countryCode &&
          market.utilitytype === this.searchParameters.utilitytype.value
        )
      })
    },
    fastSearchClass() {
      return this.device.isMobile()
        ? 'fast-search-buttons-mobile'
        : 'fast-search-buttons'
    },
    hasWeeklyData() {
      const differenceInYears = this.endDate.diff(this.startDate, 'years')
      return Math.floor(differenceInYears.years) >= 2
    },
  },
  methods: {
    setStartingParameters() {
      const savedCountry = localStorage.getItem('selectedCountry')
      if (savedCountry) {
        this.countryStore.setDefaultCountry(savedCountry)
      } else {
        this.countryStore.setDefaultCountry('nl')
      }
      this.searchParameters = {
        ...this.searchParameters,
        utilitytype: {
          name: this.$t(`general.utilitytype.${this.$route.params.util}`),
          value:
            this.$route.params.util === 'e' ? 'Electricity' : 'Natural Gas',
          key: this.$route.params.util,
        },
        pricetype: this.priceTypeStore.priceType.value,
        area: this.hasAreas
          ? this.areaStore.areas.find((area) => {
              return area.isDefaultInCountries.includes(
                this.countryStore.country.code,
              )
            })?.value
          : '-1',
        country: this.countryStore.country.code,
      }
      this.searchParameters.stripname = this.firstProduct ? [this.firstProduct] : [];
      this.priceCategory = this.priceCategoryStore.translatedPriceCategories[0]
      this.updateSeries()
    },
    onCountryChanged() {
      this.setAreas()
      this.searchParameters.country = this.countryStore.country.code
      this.searchParameters.stripname = [this.firstProduct]
      this.updateSeries()
    },
    onUtilityChanged() {
      this.setAreas()
      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-UtilityChanged' },
        {
          utility: this.searchParameters.utilitytype.value,
        },
      )
      this.updateSeries()
    },
    productsChanged() {
      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-ProductsChanged' },
        {
          products: this.searchParameters.stripname.map(
            (product) => product?.stripname,
          ),
        },
      )
      this.updateSeries()
    },
    priceFieldChanged() {
      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-PriceFieldChanged' },
        {
          priceField: this.priceCategory.value,
        },
      )
    },
    areaChanged() {
      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-AreaChanged' },
        {
          area: this.searchParameters.area,
        },
      )
      this.updateSeries()
    },
    onMobileUtilityChanged() {
      if (!this.device.isMobile()) {
        return
      }

      if (this.mobileUtilityStore.utility === 'fuel') {
        this.$router.push({
          name: 'FuelPriceGraph',
          params: {
            ...this.$route.params,
            util: 'coal',
          },
        })

        return
      }

      const nextRouteAreas =
        this.countryStore.country.code === 'de' &&
        this.mobileUtilityStore.utility === 'gas'

      this.$router.push({
        name: nextRouteAreas
          ? 'FuturePriceGraphArea'
          : 'FuturePriceGraphProduct',
        params: {
          ...this.$route.params,
          util: this.mobileUtilityStore.shortUtility,
          area: nextRouteAreas ? 1 : -1,
        },
      })
    },
    onPriceTypeChanged() {
      const newPriceType = this.priceTypeStore.priceType.value

      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-PriceCategoryChanged' },
        {
          newPriceType,
        },
      )
      this.searchParameters.pricetype = newPriceType

      this.updateSeries()
    },
    onStartDateChanged() {
      this.setAreas()

      if (this.hasAreas && this.startDate > END_DATE_NCG_GPL_MARKETS) {
        this.searchParameters.area = this.areaStore.areas.find((area) => {
          return area.isDefaultInCountries.includes(
            this.countryStore.country.code,
          )
        }) ?? { value: '-1' }
      }

      this.updateSeries()
    },
    onEndDateChanged() {
      this.setAreas()

      if (this.hasAreas && this.startDate < END_DATE_NCG_GPL_MARKETS) {
        this.searchParameters.area = this.availableAreas.find((area) => {
          return area.name === 'NCG'
        })
      }

      this.updateSeries()
    },
    updateSeries: lodash.debounce(
      function () {
        if (!this.searchParametersAreSet) {
          return
        }

        this.futurePriceStore.fetchGraphPrices({
          ...this.searchParameters,
          start: this.startDate.toFormat('yyyyLLdd'),
          end: this.endDate.toFormat('yyyyLLdd'),
          utilitytype: this.searchParameters.utilitytype.value,
          country: this.countryStore.country.code,
          market: this.getMarket(),
          stripname: this.searchParameters.stripname.map(
            (product) => product?.stripname,
          ),
          area: this.hasAreas ? this.searchParameters.area.value : '-1',
        })
      },
      500,
      {
        trailing: true,
      },
    ),
    getConsistentColor(name) {
      if (!this.searchParameters.utilitytype.key) return ''
      const util = this.searchParameters.utilitytype.key

      if (this.seriesColors[util][name]) return this.seriesColors[util][name]

      const utilityColors = chartDefaults.utilityColors[util]
      const color =
        utilityColors[
          Object.keys(this.seriesColors[util]).length % utilityColors.length
        ]
      this.seriesColors[util][name] = color
      return color
    },
    openSettings() {
      this.$refs.settingsModal.show()
    },
    openExportSettings() {
      this.$refs.exportModal.show()
    },
    exportTypeChosen(exportType) {
      this.exportType = exportType
      this.doExport()
    },
    doExport() {
      this.appInsights?.trackEvent(
        { name: 'FuturePriceGraph-PdfDownloaded' },
        {
          ...this.searchParameters,
          start: this.startDate.toFormat('yyyyLLdd'),
          end: this.endDate.toFormat('yyyyLLdd'),
          utilitytype: this.searchParameters.utilitytype.value,
          market: this.searchParameters.market
            ? this.searchParameters.market.market
            : this.markets[0].market,
          stripname: this.searchParameters.stripname.map(
            (product) => product.stripname,
          ),
          area: this.hasAreas ? this.searchParameters.area.value : '-1',
          priceField: this.priceCategory.value,
        },
      )

      // Make sure, the option is one of the 2 possible options (pdf, png)
      const chosenType = this.exportTypeStore.exportTypes
        .filter((data) => data.name === this.exportType.name)
        .shift()

      if (chosenType.value !== undefined) {
        this.$refs.graph.chart.exportChart({
          type: chosenType.value,
          filename: `${this.graphTitle.replace(',', '-')}`,
          allowHTML: true,
          formAttributes: {
            target: '_blank',
          },
        })
      }
    },
    setPeriodByYearAgo(yearsAgo) {
      yearsAgo = parseInt(yearsAgo)

      this.startDate = DateTime.now().startOf('year').minus({ years: yearsAgo })
      this.endDate = DateTime.now().startOf('day')

      this.updateSeries()
    },
    getPeriodTranslation(yearsAgo) {
      yearsAgo = parseInt(yearsAgo)

      if (this.device.isMobile()) {
        return this.$t('general.initials-years-ago') + yearsAgo === 0
          ? ''
          : '-' + yearsAgo
      }

      return (
        '1 ' +
        this.$t('general.months.1.long') +
        ' ' +
        (yearsAgo === 0
          ? DateTime.now().startOf('year').toFormat('yyyy')
          : DateTime.now()
              .startOf('year')
              .minus({ years: yearsAgo })
              .toFormat('yyyy')) +
        ' - ' +
        this.$t('general.present')
      )
    },
    setAreas() {
      // Change available areas
      this.availableAreas = this.areaStore.areas.filter((area) => {
        return area.countries.includes(this.countryStore.country.code)
      })
      // Set default area
      this.searchParameters.area = this.areaStore.areas.find((area) => {
        return area.isDefaultInCountries.includes(
          this.countryStore.country.code,
        )
      }) ?? { value: '-1' }
    },
    getMarket() {
      // TODO: For BE the Dutch market is still used until the areas are implemented (ZTP and LEBA). This logic should better be moved to the area store.
      if (this.hasAreas) {
        if (this.searchParameters.country === 'at') return 'PEGAS'
        return this.startDate > END_DATE_NCG_GPL_MARKETS ? 'PEGAS' : 'EEX' // THE and CEGH have PEGAS (NCG AND GPL have EEX)
      }

      return this.markets[0].market
    },
  },
  async mounted() {
    await Promise.all([
      this.marketStore.fetchMarkets(),
      this.productStore.fetchProducts(),
    ])

    this.setStartingParameters()
  },
  watch: {
    'priceTypeStore.priceType': function () {
      if (this.priceTypeStore.initialized) {
        this.onPriceTypeChanged()
      }
    },
    'countryStore.country': function () {
      if (this.countryStore.initialized) {
        this.onCountryChanged()
      }
    },
    'mobileUtilityStore.utility': function () {
      this.onMobileUtilityChanged()
    },
    'futurePriceStore.isLoading.graphPrices': function (isLoading) {
      if (isLoading) {
        this.$refs.graph.chart.showLoading()

        return
      }

      this.$refs.graph.chart.hideLoading()
    },
    searchParameters: {
      handler() {
        this.shouldRerender = !this.shouldRerender
      },
      deep: true,
    },
    $route(to) {
      this.setStartingParameters()
    },
    deviceType() {
      if (this.device.isMobile()) this.$refs.graph.chart.setSize(null, null)
      else this.$refs.graph.chart.setSize(null, chartDefaults.chart.height)
    },
  },
}
</script>

<style scoped lang="scss">
.fast-search-buttons {
  margin-top: 2%;
  button {
    margin-top: 10px;
    margin-right: 20px;
    font-size: 12px;
  }
}

.fast-search-buttons-mobile {
  button {
    height: 32px;
    padding-top: 8px;
    font-size: 11px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
